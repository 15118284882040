import { debounce } from './helpers';

const isNearViewport = (element) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const offset = windowHeight * 1.7;

    // Properties other than width and height are relative to the top-left of the viewport.
    // top/bottom are 0 when they are on window top,
    // minus above top, positive below top

    const isTopInView = rect.top >= 0 && rect.top <= offset;
    const isBottomInView = rect.bottom >= 0 && rect.bottom <= offset;
    // const isCenterInView = rect.top < 0 && rect.bottom >= windowHeight;

    return isTopInView || isBottomInView; // we only care about topInView
};
const loadImage = (el) => {
    const element = el;
    const imageUrl = element.dataset.bgUrl;
    element.style.backgroundImage = `url('${imageUrl}')`;
};

const lazyLoadContainer = document.querySelector('.js-ll-wrapper');

if (lazyLoadContainer) {
    document.addEventListener('DOMContentLoaded', () => {
        const watchedElements = Array.prototype.slice.call(document.querySelectorAll('.js-ll'));
        if (watchedElements.length) {
            const scrollHandler = debounce(() => {
                for (let i = 0; i < watchedElements.length; i++) {
                    if (isNearViewport(watchedElements[i])) {
                        loadImage(watchedElements[i].querySelector('.js-ll-img'));
                        watchedElements[i].classList.add('item-reveal');
                        watchedElements.splice(i, 1);
                        i -= 1;
                    }
                }
                if (!watchedElements.length) {
                    window.removeEventListener('scroll', scrollHandler);
                    window.removeEventListener('resize', scrollHandler);
                }
            }, 80);
            window.addEventListener('scroll', scrollHandler, { passive: true });
            window.addEventListener('load', scrollHandler);
            window.addEventListener('resize', scrollHandler, { passive: true });
        }
    });
}
