/* global Sine */
/* eslint no-unused-vars: off */

import TweenMax from 'gsap/src/uncompressed/TweenMax';
import TimelineMax from 'gsap/src/uncompressed/TimelineMax';

function pageIntroLoad() {
    const pageIntro = document.querySelector('.page-intro-section');
    if (pageIntro) {
        const sectionMedia = pageIntro.querySelector('.page-intro__media-wrapper');
        const sectionContent = pageIntro.querySelector('.page-intro__content-wrapper');
        const scrollIcon = pageIntro.querySelector('.scroll-icon-wrapper');
        const sectionElements = [sectionContent, scrollIcon];

        const tl = new TimelineMax()
            .add(TweenMax.fromTo(
                sectionMedia,
                1.5,
                {
                    autoAlpha: 0,
                    y: -50,
                    force3D: true,
                    ease: Sine.easeOut,
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
            ))
            .add(TweenMax.fromTo(
                sectionElements,
                1,
                {
                    autoAlpha: 0,
                    y: -50,
                    force3D: true,
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
            ), '-=0.5');
    }
}

window.addEventListener('load', () => {
    const eventDateElementWrapper = document.querySelector('.page-event-year');
    if (eventDateElementWrapper) {
        eventDateElementWrapper.classList.add('animate', 'show');
    }
    // Reveal Projects on load
    // const projectsContainer = document.querySelector('.js-project-items-container');
    const projectsContainer = document.querySelector('.projects-main-section');
    if (projectsContainer) {
        // Reveal page title and page suptitle
        const pageTitle = document.querySelector('.projects-main-section .section-title');
        const pageSuptitle = document.querySelector('.projects-main-section .section-suptitle');
        if (pageTitle) {
            pageTitle.classList.add('animate', 'show');
        }
        if (pageSuptitle) {
            pageSuptitle.classList.add('animate', 'show');
        }
        const projects = projectsContainer.querySelectorAll('.js-project-item');
        for (let i = 0; i < projects.length; i++) {
            const time = 500 + (Math.random() * 500);
            setTimeout(() => {
                projects[i].classList.add('item-reveal');
            }, time);
        }
    }
    // Single Application on load
    const singleApplicationWrapper = document.querySelector('.single-application-main-section');
    if (singleApplicationWrapper) {
        const pageTitle = singleApplicationWrapper.querySelector('.section-title');
        const pageSuptitle = singleApplicationWrapper.querySelector('.section-suptitle');
        if (pageTitle) {
            pageTitle.classList.add('animate', 'show');
        }
        if (pageSuptitle) {
            pageSuptitle.classList.add('animate', 'show');
        }
    }
    // Page intro load animation except home page
    if (document.querySelector('.fino-home') === null) {
        pageIntroLoad();
    }
});
